import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';

import Authorize from 'features/auth/components/Auth/Auth';
import VipListContainer from 'features/vip/containers/VipListContainer';
import VipPackageContainer from 'features/vip/containers/VipPackageContainer';
import ContentHeader from 'features/generic/components/ContentHeader/ContentHeader';
import DashboardListingsContainer from 'features/dashboard/containers/DashboardListingsContainer';
import DashboardTableContainer from 'features/dashboard/containers/DashboardTableContainer';
import {selectCurrentUser} from 'features/users/selectors';
import {getAccount} from 'features/accounts/actions';
import {withRouter} from '../../../../utils/helpers';

const GraphContainer = React.lazy(() =>
    import('features/dashboard/containers/GraphContainer'),
);

import {
    VipContainer,
    VipPackage,
    Divider,
    TabContainer,
    TabsContainer,
    ContentHeaderCss,
} from './styles';

import BirdeyeBusinessesContainer from "features/listings/containers/BirdeyeBusinessesContainer";

/**
 * Hide line graph from internal users for RB Account
 */
let Dashboard = (props) => {
    const currentAccountId = useSelector((state) => state.accounts.currentAccountId);
    const accounts = useSelector((state) => state.accounts.entities);

    useEffect(() => {
        // helpful to have account available in state (right after login) for authorization. Please don't remove. Will have global effects
        if (props.user.account_id) {
            props.getAccount(props.user.account_id);
        }
    }, []);

    return (
        <>
            <ContentHeader $css={ContentHeaderCss}>
                {currentAccountId &&
                    accounts?.[currentAccountId]?.organization_name &&
                    `${accounts?.[currentAccountId]?.organization_name} `}
                Overview
            </ContentHeader>
            <Authorize authorize={(user) => user.type === 'external'}>
                <GraphContainer currentAccountId={currentAccountId} {...props} />
                <DashboardTableContainer {...props} />
            </Authorize>
            <Authorize authorize={(user) => user.type !== 'external'}>
                <DashboardListingsContainer {...props} />
            </Authorize>
            <Authorize permissions={['vipSubscription-view-all', 'vipPackage-view-all']}>
                <TabsContainer>
                    <TabContainer>VIP</TabContainer>
                    <Divider />
                </TabsContainer>
            </Authorize>
            <Authorize permissions={['vipSubscription-view-all', 'vipPackage-view-all']}>
                <VipContainer>
                    <VipListContainer />
                    <VipPackageContainer $css={VipPackage} />
                </VipContainer>
            </Authorize>
            <Authorize authorize={(user) => user.type !== 'external'}>
                <BirdeyeBusinessesContainer {...props} />
            </Authorize>
            {/* <KnowledgeBaseContainer /> */}
        </>
    );
};

export default withRouter(
    connect(
        (state, ownProps) => {
            return {
                user: selectCurrentUser(state),
            };
        },
        {
            getAccount,
        },
    )(Dashboard),
);
