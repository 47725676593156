import React from 'react';

import {TileStyled} from 'features/generic/components/Tile/Tile';
import Loading from 'features/generic/components/Loading/Loading';
import Content from 'features/generic/components/Content/Content';

import {
    TableHeaderCellStyled,
    TableHeaderRowStyled,
    TableRowStyled,
} from 'features/generic/components/Table/Table';
import {isEmpty, isArray} from 'utils/helpers';

import columns from './columns';
import {ContentHeader, ContentHeaderAction, Empty, RowCss, TileCss} from './styles';

const BirdeyeBusinessesInfo = (props) => {
    const {businesses} = props;
    const hasBusinesses = isArray(businesses) && !isEmpty(businesses);

    return (
        <div>
            <div>
                <ContentHeader>Birdeye Businesses</ContentHeader>
                {props.isLoading && (<ContentHeaderAction>Loading...</ContentHeaderAction>)}
                {props.isEditing && (<ContentHeaderAction>Saving...</ContentHeaderAction>)}
                {props.isDeleting && (<ContentHeaderAction>Deleting...</ContentHeaderAction>)}
            </div>
            <Content>
                <TileStyled $css={TileCss}>
                    <TableHeaderRowStyled>
                        {columns.map((column, i) => (
                            <TableHeaderCellStyled
                                key={column.flex + i}
                                flex={column.flex}>
                                <column.HeaderComponent
                                    {...props}
                                    {...column}
                                    key={i}
                                />
                            </TableHeaderCellStyled>
                        ))}
                    </TableHeaderRowStyled>
                    {props.isFetching && (
                        <TableRowStyled $css={RowCss}>
                            <Loading message="Fetching..." />
                        </TableRowStyled>
                    )}
                    {!hasBusinesses && !props.isFetching && (
                        <Empty>No Birdeye Businesses Found</Empty>
                    )}
                    {hasBusinesses &&
                        !props.isFetching &&
                        props.businesses.map((business) => {
                            /**
                             * Previous Issue: listing had all null fields, check existence first
                             */
                            return (
                                <TableRowStyled $css={RowCss} key={business.listing_id}>
                                    {columns.map((column, i) => (
                                        <column.ChildComponent
                                            {...props}
                                            {...column}
                                            key={i}
                                            business={business}
                                        />
                                    ))}
                                </TableRowStyled>
                            );
                        })}
                </TileStyled>
            </Content>
        </div>
    );
};

export default BirdeyeBusinessesInfo;
