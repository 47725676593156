import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';

import {notifyErrorWithTimeout} from 'features/notification/slice';

import {selectCurrentUser} from 'features/users/selectors';
import {isEmpty} from 'utils/helpers';
import {
    fetchBirdeyeBusinesses,
    deleteBirdeyeBusiness,
    editBirdeyeBusiness,
} from 'features/birdeyeBusinesses/slice';
import {withRouter} from 'utils/helpers';
import BirdeyeBusinessesInfo from "features/listings/components/BirdeyeBusinessesInfo/BirdeyeBusinessesInfo";
import {
    selectBirdeyeBusinesses,
    selectIsLoading,
    selectIsEditing,
    selectIsDeleting,
} from "features/birdeyeBusinesses/selectors";
import {hideModal, toggleModal} from "features/modal/slice";

let BirdeyeBusinessesContainer = (props) => {

    useEffect(() => {
        if (isEmpty(props.birdeyeBusinesses) || props.isFetching) {
            props.fetchBirdeyeBusinesses();
        }
    }, []);

    return (
        <BirdeyeBusinessesInfo
            {...props}
        />
    );
};

BirdeyeBusinessesContainer = connect(
    (state, ownProps) => ({
        user: selectCurrentUser(state),
        isFetching: state.birdeyeBusinesses.isFetching,
        modal: state.modal,
        businesses: selectBirdeyeBusinesses(state),
        isLoading: selectIsLoading(state),
        isEditing: selectIsEditing(state),
        isDeleting: selectIsDeleting(state),
    }),
    {
        fetchBirdeyeBusinesses,
        deleteBirdeyeBusiness,
        editBirdeyeBusiness,
        notifyErrorWithTimeout,
        hideModal,
        toggleModal,
    },
)(BirdeyeBusinessesContainer);

export default withRouter(BirdeyeBusinessesContainer);
