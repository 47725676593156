import React, { useState } from 'react';

import Authorize from 'features/auth/components/Auth/Auth';
import Modal from 'features/modal/components/Modal/Modal';
import {TableCellStyled} from 'features/generic/components/Table/Table';
import {
    formatListingTypeDisplayName,
} from 'utils/helpers';

import {LinkStyled} from 'features/generic/components/Table/styles';
import {
    ActionsContainer,
    BirdeyeModalContent,
    BirdeyeModalHeader,
    BirdeyeModalHeaderContainer,
    BusinessIdContainer,
    BusinessIdInput,
    EditPencilIcon, Error,
    ListingTypeContainer,
    OverflowCss,
    TrashCanIcon,
} from './styles';
import Button from 'features/generic/components/Button/Button';

const columns = [
    {
        flex: 0.5,
        sortKey: 'listing_name',
        HeaderComponent: ({flex}) => <span>Listing Name</span>,
        ChildComponent: (props) => (
            <TableCellStyled flex={props.flex}>
                <LinkStyled href={`/listing/${props.business.listing_id}/details`}>
                    {props.business.listing_name}
                </LinkStyled>
            </TableCellStyled>
        ),
    },
    {
        flex: 0.4,
        sortKey: 'business_id',
        HeaderComponent: ({flex}) => <span>Birdeye Business ID</span>,
        ChildComponent: (props) => {

            return (
                <TableCellStyled flex={props.flex}>
                    <BusinessIdContainer>{props.business.business_id}</BusinessIdContainer>
                </TableCellStyled>
            );
        }
    },
    {
        flex: 0.25,
        sortKey: 'listing_type',
        HeaderComponent: () => <span>Listing Type</span>,
        ChildComponent: (props) => {
            const {business, flex} = props;
            const currentType = business.listing_type;
            return (
                <TableCellStyled $css={OverflowCss} flex={flex}>
                    <ListingTypeContainer>
                        <span>{formatListingTypeDisplayName(currentType)}</span>
                    </ListingTypeContainer>
                </TableCellStyled>
            );
        },
    },
    {
        flex: 0.15,
        HeaderComponent: () => <span>Actions</span>,
        ChildComponent: (props) => {
            const {business, flex, modal} = props;
            const showPanelDelete =
                modal.type === 'BIRDEYE_BUSINESS_DELETE' && modal.props.id === business.listing_id;
            const showPanelEdit =
                modal.type === 'BIRDEYE_BUSINESS_EDIT' && modal.props.id === business.listing_id;
            const [ businessId, setBusinessId ] = useState(business.business_id);
            const handleEditChange = (e) => {
                setBusinessId(e.target.value);
            };

            const isAlphaNumeric = businessId => /^[A-Za-z0-9]+$/i.test(businessId);
            const notSameAsOriginal = (businessId, originalBusId) => businessId !== originalBusId;
            const isInLengthRange = businessId => businessId?.length > 3 && businessId?.length < 31;
            const isValidBusinessId = (businessId, originalBusId) => {
                return isInLengthRange(businessId) && notSameAsOriginal(businessId, originalBusId) && isAlphaNumeric(businessId);
            };

            return (
                <TableCellStyled $css={OverflowCss} flex={flex}>
                    <ListingTypeContainer>
                        <Authorize permissions={['listing-edit-all']}>
                            <ActionsContainer>
                                <EditPencilIcon
                                    onClick={(e) => {
                                        props.toggleModal({
                                            type: 'BIRDEYE_BUSINESS_EDIT',
                                            props: {
                                                id: business.listing_id,
                                            },
                                        });
                                        setBusinessId(business.business_id);
                                        e.stopPropagation();
                                    }}
                                />
                                <TrashCanIcon
                                    onClick={(e) => {
                                        props.toggleModal({
                                            type: 'BIRDEYE_BUSINESS_DELETE',
                                            props: {
                                                id: business.listing_id,
                                            },
                                        });
                                        e.stopPropagation();
                                    }}
                                />
                                {showPanelDelete && (
                                    <Modal handleClose={props.hideModal}>
                                        <BirdeyeModalHeaderContainer>
                                            <BirdeyeModalHeader>
                                                Delete Birdeye Business ID?
                                            </BirdeyeModalHeader>
                                            <Authorize permissions={['listing-edit-all']}>
                                                <BirdeyeModalContent>
                                                <Button onClick={props.hideModal}
                                                        style={{display: "inline", marginRight: '20px'}}
                                                        theme="blank"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    style={{display: "inline"}}
                                                    onClick={() => {
                                                        props.deleteBirdeyeBusiness(business.listing_id);
                                                        props.hideModal();
                                                    }}>
                                                    Yes
                                                </Button>
                                                </BirdeyeModalContent>
                                            </Authorize>
                                        </BirdeyeModalHeaderContainer>
                                    </Modal>
                                )}
                                {showPanelEdit && (
                                    <Modal handleClose={props.hideModal}>
                                        <BirdeyeModalHeaderContainer>
                                            <BirdeyeModalHeader>
                                                Edit Birdeye Business ID
                                            </BirdeyeModalHeader>
                                            <Authorize permissions={['listing-edit-all']}>
                                                <BirdeyeModalContent>
                                                    <BusinessIdInput onChange={handleEditChange} defaultValue={business.business_id}></BusinessIdInput>
                                                    {!isAlphaNumeric(businessId) && (<Error>Must only have numbers or letters</Error>)}
                                                    {!notSameAsOriginal(businessId, business.business_id) && (<Error>Must be different than original</Error>)}
                                                    {!isInLengthRange(businessId) && (<Error>Must between 4 and 30 characters</Error>)}
                                                    <div>
                                                    <Button onClick={props.hideModal}
                                                            style={{display: "inline", marginRight: '20px'}}
                                                            theme="blank"
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        style={{display: "inline"}}
                                                        disabled={!isValidBusinessId(businessId, business.business_id)}
                                                        onClick={() => {
                                                            props.editBirdeyeBusiness(business.listing_id, businessId);
                                                            props.hideModal();
                                                        }}>
                                                        Save
                                                    </Button>
                                                    </div>
                                                </BirdeyeModalContent>
                                            </Authorize>
                                        </BirdeyeModalHeaderContainer>
                                    </Modal>
                                )}
                            </ActionsContainer>
                        </Authorize>
                    </ListingTypeContainer>
                </TableCellStyled>
            );
        },
    },
];

export default columns;
