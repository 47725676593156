import {COLORS, bp, hexToRgb, newCOLORS, BOX_SHADOW, BOX_SHADOW_MODAL} from 'core/styles';
import {css} from 'styled-components';
import styled from 'styled-components';
import Trashcan from 'assets/img/icons/trash-can.svg';
import EditPencil from 'assets/img/icons/edit.svg';

export const TileCss = css`
    padding: 0;
`;

export const RowCss = css`
    padding-top: 20px;
    padding-bottom: 20px;
`;

export const Empty = styled.h3`
    color: ${COLORS.grayFont};
    padding: 15px;
`;

export const OverflowCss = css`
    overflow: visible;
`;

export const ListingTypeContainer = styled.div`
    color: ${COLORS.grayFont};
    margin: 0;
    display: flex;
    font-weight: 500;
    font-size: 12px;
    padding: 0;
    text-align: left;
    margin-bottom: 0;
`;

export const ActionsContainer = styled.div`
    color: ${COLORS.grayFont};
    margin: 0;
    flex: 1 1 0;
    font-weight: 500;
    font-size: 11px;
    margin-right: 10px;
    padding: 0 0 0 10px;
    text-align: left;
    margin-bottom: 0;
`;

export const TrashCanIcon = styled (Trashcan)`
    width: 25px;
    cursor: pointer;
    &:hover {
        filter: brightness(130%);
    }
`;

export const EditPencilIcon = styled (EditPencil)`
    width: 25px;
    margin-right: 15px;
    color: ${newCOLORS.gray400};
    cursor: pointer;
    filter: invert(42%) saturate(1352%) brightness(119%) contrast(119%);
    &:hover {
        filter: invert(42%) saturate(1352%) brightness(150%) contrast(119%);
    }
`;

export const BirdeyeModalHeaderContainer = styled.div`
    width: 300px;
    position: absolute;
    right: 90px;
    top: -32px;
    max-height: 200px;
    border-radius: 16px 16px 16px 16px;
    border: 1px ${newCOLORS.gray300} solid;
    background-color: #fff;
    box-shadow: ${BOX_SHADOW_MODAL};
`;

export const BirdeyeModalHeader = styled.div`
    margin: auto;
    width: 100%;
    text-align: center;
    background-color: ${newCOLORS.gray100};
    height: 45px;
    padding: 10px 0px 10px 0px;
    border-radius: 16px 16px 0px 0px;
    border-bottom: 1px ${newCOLORS.gray300} solid;
`;

export const BirdeyeModalContent = styled.div`
    vertical-align: middle;
    width: 100%;
    padding: 15px;
    border-radius: 0px 0px 16px 16px;
    text-align: center;
    margin-bottom: 20px;
`;

export const BusinessIdContainer = styled.div`
    color: ${COLORS.grayFont};
    margin: 0;
    display: inline;
    font-weight: 500;
    font-size: 12px;
    padding: 0;
    text-align: left;
    margin-bottom: 0;
`;

export const BusinessIdEditContainer = styled.div`
    width: 100%;
    display: inline;
    margin-right: 20px;
    padding: 0px;
    text-align: right;
`;

export const BusinessIdInput = styled.input`
    padding: 5px 3px;
    font-size: 16px;
    line-height: 20px;
    margin-right: 16px;
    margin-bottom: 10px;
    display: inline;
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${newCOLORS.gray400};
    background: #fff;
    color: ${newCOLORS.gray500};
`;

export const Error = styled.div`
    color: ${COLORS.danger};
    font-size: 12px;
    margin-bottom: 16px;
`;

export const ContentHeader = styled.span`
    font-weight: bold;
    font-size: 18px;
    margin-left: 50px;
`;
export const ContentHeaderAction = styled.span`
    color: #fff;
    background-color: ${COLORS.primary};
    font-size: 13px;
    margin-left: 20px;
    padding: 5px;
    border-radius: 5px;
`;